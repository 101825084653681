'use strict';

import '../assets/styles/styles.sass';
import 'angular';
import 'angular-format-masks';
import 'angular-spinner';
import 'angulartics';
import 'angulartics-google-tag-manager';
import 'angular-input-masks';
import 'angular-sanitize';
import 'angular-touch';
import 'ngstorage';
import 'oclazyload';
import 'spin.js';
import '@uirouter/core';
import '@uirouter/angularjs';
import '@celulardireto/wza-directives';
import '@celulardireto/wza-services';
import 'angular-animate';
import '@celulardireto/wza-checkbox-radio';
import '@celulardireto/wza-select';
import '@celulardireto/wza-uf-ddd';
import '@celulardireto/wza-vrj-mobile-plan-comparison';
import 'iframe-resizer/js/iframeResizer.contentWindow';
// @TODO ver como melhorar o uso do jquery ou remover
import $ from 'jquery';

import { AppAnalytics } from './app.analytics';
import { AppConfig } from './app.config';
import { AppConstants } from './app.constants';
import { AppRouter } from './app.router';
import { AppRun } from './app.run';
import { AppPages } from '../pages/pages.module';

window.$ = $;

export const AppModule = angular
    .module('varejo.multiplanos', [
        'angularSpinner',
        'angulartics',
        'angulartics.google.tagmanager',
        'format.masks',
        'ngLocale',
        'ngSanitize',
        'ngAnimate',
        'ngTouch',
        'wzaVrjMobilePlanComparison',
        'oc.lazyLoad',
        'ui.router',
        'ui.utils.masks',
        'wza.services',
        'wza.directives',
        'wza.checkboxRadio',
        'wza.select',
        'wza.ufDdd',
        AppPages
    ])
    .name;

AppAnalytics.configure();
AppConfig.configure();
AppConstants.configure();
AppRouter.configure();
AppRun.configure();
