'use strict';

export const PageIframeRouter = {
    name: 'iframe',
    component: 'wzaIframe',
    url: '/iframe'
    // lazyLoad: ($transition$) => {
    //     const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

    //     return require.ensure([], () => {
    //         if (process.env.NODE_ENV == 'test') {
    //             import('./iframe.module');
    //         } else {
    //             require('./iframe.module');
    //         }

    //         $ocLazyLoad.load({ name: 'iframe.page' });
    //     }, 'iframe.page');
    // }
};
