'use strict';

import { PagesRoutes } from './pages.routes';
import { PageIframeModule } from './iframe/iframe.module';
import { PageHomeModule } from './home/home.module';
import { PageSmsModule } from './sms/sms.module';
import { OptionBoxesModule } from '../components/option-boxes/option-boxes.module';
import { CpgBannerModule } from '../components/cpg-banner/cpg-banner.module';
import { FaqModule } from '../components/faq/faq.module';
import { AccordionModule } from '../components/accordion/accordion.module';

export const AppPages = angular
    .module('varejo.multiplanos.pages', [
        PageIframeModule,
        PageHomeModule,
        PageSmsModule,
        OptionBoxesModule,
        CpgBannerModule,
        FaqModule,
        AccordionModule
    ])
    .config(PagesRoutes)
    .name;
