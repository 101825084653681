'use strict';

import { PageHomeRouter } from './home/home.router';
import { PageIframeRouter } from './iframe/iframe.router';
import { PageSmsRouter } from './sms/sms.router';

/* @ngInject */
export const PagesRoutes = ($stateProvider) => {
    $stateProvider
        .state(PageHomeRouter)
        .state(PageSmsRouter)
        .state(PageIframeRouter);
};
