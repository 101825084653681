'use strict';

export const PageHomeRouter = {
    name: 'home',
    component: 'home',
    url: '/:theme'
    // lazyLoad: ($transition$) => {
    //     const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');

    //     return require.ensure([], () => {
    //         if (process.env.NODE_ENV == 'test') {
    //             import('./home.module');
    //         } else {
    //             require('./home.module');
    //         }

    //         $ocLazyLoad.load({ name: 'home.page' });
    //     }, 'home.page');
    // }
};
