'use strict';

import { PageHomeController } from './home.controller';
import PageHomeTemplate from './home.template.html';

export class PageHomeComponent {
    constructor() {
        this.bindings = {};
        this.template = PageHomeTemplate;
        this.controller = PageHomeController;
    }
};
