'use strict';

import { OptionBoxesController } from './option-boxes.controller';
import OptionBoxesTemplate from './option-boxes.template.html';

export class OptionBoxesComponent {
    constructor() {
        this.bindings = {
            type: '@'
        };
        this.template = OptionBoxesTemplate;
        this.controller = OptionBoxesController;
    }
};
