'use strict';

import { AccordionController } from './accordion.controller';
import AccordionTemplate from './accordion.template.html';

export class AccordionComponent {
    constructor() {
        this.bindings = {
            title: '@',
            text: '@',
            idRef: '@',
            closeRef: '='
        };
        this.transclude = true;
        this.template = AccordionTemplate;
        this.controller = AccordionController;
    }
};
