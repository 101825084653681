'use strict';

import { CpgBannerController } from './cpg-banner.controller';
import CpgBannerTemplate from './cpg-banner.template.html';

export class CpgBannerComponent {
    constructor() {
        this.bindings = {
            type: '@'
        };
        this.template = CpgBannerTemplate;
        this.controller = CpgBannerController;
    }
};
