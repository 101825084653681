'use strict';

import { FILTERS } from './home.constant';

export class PageHomeController {
    constructor($state, $timeout, ParametersService, $location) {
        'ngInject';
        this.ParametersService = ParametersService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$location = $location;
    }


    $onInit() {
        var params = this.$location.search();
        this.theme = this.$state.params.theme || 'americanas';

        if(this.theme == 'lp-americanas') {
            this.abActivate = true;
        } else {
            this.filters = {
                operatorCode: params.op && {[FILTERS.operatorCode[params.op - 1]]: true},
                dataValue: params.dv && {[FILTERS.dataValue[params.dv - 1]]: true},
                planValue: params.pv && {[FILTERS.planValue[params.pv - 1]]: true},
                payment: params.py && {[FILTERS.payment[params.py - 1]]: true}
            };
        }

        this.order = 'relevant';
        this.constants = {
            ENV, API_URL, COMP_VERSION,
            ORIGIN_CODE: CLIENT_CONFIG[this.theme].ORIGIN_CODE,
            AUTHORIZATION: CLIENT_CONFIG[this.theme].AUTHORIZATION,
            USER_SELLER: CLIENT_CONFIG[this.theme].USER_SELLER,
            IS_MODAL_EXTERNAL: CLIENT_CONFIG[this.theme].IS_MODAL_EXTERNAL
        };
        this.dataLayer = window.dataLayer;
        this.dddDefault = params.ddd;
        this.skuDefault = params.sku;
        this.ufDefault = params.uf;

        if (this.theme === 'portalcd' && params.us) {
            this.constants.USER_SELLER = params.us;
        } else if (this.theme === 'americanas') {
            this.loadAdobeTag();
        }
    }

    loadAdobeTag() {
        // Append on header
        this.$timeout(() => {
            const head = document.querySelector('head');
            let scriptHeaderAdobe = document.createElement('script');

            scriptHeaderAdobe.src = '//assets.adobedtm.com/296b83a81627fd662c5aecf8289129e20324f9b7/satelliteLib-59f2f2a2f3f629e6de95ca25dc507a65c9091957.js';
            head.appendChild(scriptHeaderAdobe);
        },2000);
        // Append on body
        this.$timeout(() => {
            const body = document.querySelector('body');
            let scriptBodyAdobe = document.createElement('script');

            scriptBodyAdobe.type = 'text/javascript';
            scriptBodyAdobe.innerHTML = '_satellite.pageBottom();';
            body.appendChild(scriptBodyAdobe);
        }, 4500);
    }

};
