'use strict';

export class PageSmsController {
    constructor($state, $timeout, ParametersService) {
        'ngInject';
        this._addModalListener();
        this.ParametersService = ParametersService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.uf = this.ParametersService.allParameters.uf;
        this.ddd = this.ParametersService.allParameters.ddd;
        this.sku = this.ParametersService.allParameters.sku;
        this.operator = this.ParametersService.allParameters.operator;
        this.theme = this.$state.params.client;
        this.loadComp = false;
    }

    _addModalListener() {
        const sources = [
            '//cdn.celulardireto.com.br/Varejo/modal-listener.bundle.js',
            '//cdn.celulardireto.com.br/Varejo/iframe-resizer.js'
        ];
        const body = document.getElementsByTagName('body')[0];

        sources.forEach((source) => {
            const script = document.createElement('script');
            script.setAttribute('src', source);
            body.appendChild(script);
        });
    }


    $onInit() {
        this.$timeout(() => {
            this.loadComp = true;
        }, 500);

        var operatorEnum = {
            'claro': 21,
            'vivo': 15,
            'tim': 41,
            'oi': 31
        };

        this.filters = {
            operatorCode: this.operator && {[operatorEnum[this.operator]]: true}
        };

        this.constants = {
            API_URL: API_URL,
            ORIGIN_CODE: CLIENT_CONFIG[this.theme].ORIGIN_CODE,
            AUTHORIZATION: CLIENT_CONFIG[this.theme].AUTHORIZATION,
            USER_SELLER: CLIENT_CONFIG[this.theme].USER_SELLER,
            IS_MODAL_EXTERNAL: CLIENT_CONFIG[this.theme].IS_MODAL_EXTERNAL
        };
        this.dataLayer = window.dataLayer;
    }

};
