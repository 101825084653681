export const OPTIONS = {
    operatorCode: {
        title: 'clique na sua operadora preferida e escolha o plano que mais combina com você',
        values: [
            {value: 'claro',isImage: true, queryString: 'op=1'},
            {value: 'oi',isImage: true, queryString: 'op=2'},
            {value: 'tim',isImage: true, queryString: 'op=3'},
            {value: 'vivo',isImage: true, queryString: 'op=4'}
        ],
        buttonText: 'eu queeero!'
    },
    dataValue: {
        title: 'quanto de internet você usa? escolha de acordo com seu estilo',
        values: [
            {label: 'gostei :)', value: '2.5 GB a 3.5 GB', queryString: 'dv=1'},
            {label: 'esse eu curti', value: '4.0 GB a 5.0 GB', queryString: 'dv=2'},
            {label: 'eu queeero ostentar', value: 'mais de 5.0 GB', queryString: 'dv=3'}
        ],
        buttonText: 'veeem!'
    },
    planValue: {
        title: 'se preferir, escolha o valor que cabe no seu bolso',
        values: [
            {label: 'bom e barato',value: 'R$ 39,99 a R$ 54,99', queryString: 'pv=1'},
            {label: 'sob medida',value: 'R$ 55,00 a R$ 74,99', queryString: 'pv=2'},
            {label: 'perfeito ;)',value: 'mais de R$ 75,00', queryString: 'pv=3'}
        ],
        buttonText: 'veeem!'
    }
};
