'use strict';

import { PageSmsController } from './sms.controller';
import PageSmsTemplate from './sms.template.html';

export class PageSmsComponent {
    constructor() {
        this.bindings = {};
        this.template = PageSmsTemplate;
        this.controller = PageSmsController;
    }
};
