'use strict';

import { PageIframeController } from './iframe.controller';
import PageIframeTemplate from './iframe.template.html';

export class PageIframeComponent {
    constructor() {
        this.bindings = {
        };
        this.template = PageIframeTemplate;
        this.controller = PageIframeController;
    }
};
