'use strict';

import { QUESTIONS } from './faq.constant';

export class FaqController {
    constructor($state, $timeout, ParametersService, $location) {
        'ngInject';
        this.ParametersService = ParametersService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$location = $location;
        this.closeAccordion = [];
    }

    cleanUrl() {
        location = location.pathname;
    }


    $onInit() {
        this.questions = QUESTIONS;
    }

};
