'use strict';

export class PageIframeController {
    constructor($sce, $state, $location) {
        'ngInject';
        this._addModalListener();
        this.sce = $sce;
        this.$state = $state;
        this.$location = $location;
    }

    $onInit() {
        this.theme = this.$state.params.theme || 'americanas';
        this.vendor = {
            selected: this.theme,
            options: Object.keys(CLIENT_CONFIG)
        };
        this.changeTheme(this.vendor.selected);
    }

    _addModalListener() {
        const sources = [
            '//cdn.celulardireto.com.br/Varejo/modal-listener.bundle.js',
            '//cdn.celulardireto.com.br/Varejo/iframe-resizer.js'
        ];
        const body = document.getElementsByTagName('body')[0];

        sources.forEach((source) => {
            const script = document.createElement('script');
            script.setAttribute('src', source);
            body.appendChild(script);
        });
    }

    changeTheme(userSeller) {
        var { ddd, sku, uf } = this.$location.search();
        var theme = this.vendor.selected;
        var params = '';

        if (userSeller) {
            params += `&us=${userSeller}`;
        }
        if (angular.isString(ddd)) {
            params += `&ddd=${ddd}`;
        }
        if (angular.isString(sku)) {
            params += `&sku=${sku}`;
        }
        if (angular.isString(uf)) {
            params += `&uf=${uf}`;
        }

        this.iframeSrc = this.sce.trustAsResourceUrl(`${location.origin}/${theme}?${params}`);
    }

    sendUserSeller(userSeller) {
        this.changeTheme(userSeller);
    }
};
