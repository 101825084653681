export const FILTERS = {
    operatorCode: [21,31,41,15],
    dataValue: [
        'null_3.9',
        '4.0_5.0',
        '5.0_null'
    ],
    planValue: [
        '39_55',
        '55_75',
        '75_null'
    ],
    payment: [1,2]
};
