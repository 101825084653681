'use strict';

import { OPTIONS } from './cpg-banner.constant';

export class CpgBannerController {
    constructor($state, $timeout, ParametersService, $location, $sce) {
        'ngInject';
        this.ParametersService = ParametersService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$location = $location;
        this.sce = $sce;
    }

    cleanUrl() {
        location = '/americanas?preventOpt';
    }


    $onInit() {
        this.void = this.sce.trustAsResourceUrl('javascript:void(0)');
        return;
    }

};
