'use strict';

import { OPTIONS } from './option-boxes.constant';

export class OptionBoxesController {
    constructor($state, $timeout, ParametersService, $location, $sce) {
        'ngInject';
        this.ParametersService = ParametersService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$location = $location;
        this.sce = $sce;
    }

    insertQueryString(queryString) {
        location = '/americanas' + '?' + queryString;
    }


    $onInit() {
        this.option = OPTIONS[this.type];
        this.pathname = location.pathname;
        this.void = this.sce.trustAsResourceUrl('javascript:void(0)');
        return;
    }

};
