'use strict';

export class AccordionController {
    constructor() {
        'ngInject';
        this.isExpand = false;
    }

    $onInit() {
        if(this.closeRef) {
            if(!angular.isArray(this.closeRef)) {
                this.closeRef = [];
            }
            this.closeRef.push({id: this.idRef, expanded: this.close.bind(this)});
        }
    }

    troggle() {
        this.isExpand = !this.isExpand;

        if(this.closeRef.length) {
            this.closeRef.forEach((item) => {
                if(item.id !== this.idRef) item.expanded();
            });
        }
    }

    close() {
        this.isExpand = false;
    }

};
