'use strict';

import { FaqController } from './faq.controller';
import FaqTemplate from './faq.template.html';

export class FaqComponent {
    constructor() {
        this.bindings = {
            type: '@'
        };
        this.template = FaqTemplate;
        this.controller = FaqController;
    }
};
