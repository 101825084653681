export const QUESTIONS = [
    {
        title: 'Como funciona o plano controle?',
        text: 'No plano Controle você paga um valor fixo por mês e tem um pacote com uma quantidade específica de gigas de internet, minutos para falar e outros benefícios (de acordo com a operadora e o plano contratado).'
    },
    {
        title: 'Posso contratar mais internet se a minha franquia acabar?',
        text: 'Sim. Se você consumir 100% da sua franquia é possível contratar um pacote adicional, que vai ser pago na sua fatura mensal.'
    },
    {
        title: 'O pacote de internet é diário?',
        text: 'Não. A franquia de internet é mensal. Isso quer dizer que você vai ter uma quantidade limitada de gigas para usar no período de um mês. Caso você consuma toda a franquia em menos tempo, é possível contratar um pacote adicional diretamente com a operadora.'
    },
    {
        title: 'Preciso ter um aparelho 4G?',
        text: 'Não. Os planos são compatíveis com as tecnologias 3G e 4G. Caso o seu smartphone não seja 4G, poderá navegar pela internet através da conexão 3G.'
    },
    {
        title: 'Não tenho chip, preciso comprar um antes de fazer um plano?',
        text: 'Não. O chip da operadora contratada será enviado gratuitamente para o endereço cadastrado por você. Caso tenha um chip de outra operadora e queira manter o seu número, basta solicitar a portabilidade.'
    },
    {
        title: 'Preciso mudar meu número para contratar o plano?',
        text: 'Não. Basta contratar o pacote que a operadora cuidará de tudo! Você pode mudar para um plano da sua própria operadora atual, ou mesmo de outra empresa. Neste caso, a portabilidade será feita sem que você precise contatar a sua operadora.'
    },
    {
        title: 'Posso contratar um plano de uma operadora diferente da minha atual?',
        text: 'Sim. Caso queria contratar o plano de uma operadora diferente da sua, a empresa escolhida faz a portabilidade para você.'
    },
    {
        title: 'Quem tem menos de 18 anos pode fazer o plano?',
        text: 'Não. Os planos precisam ser contratados por maiores de 18 anos. Caso você seja menor, pode pedir a um responsável para contratar por você.'
    }
];
